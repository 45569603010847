@font-face {
	font-family: AkzidenzGroteskPro;
	src: url(../assets/fonts/AkzidenzGroteskPro-ExtItalic.woff2);
	font-style: italic;
}

@font-face {
	font-family: Share;
	src: url(../assets//fonts/Share-Regular.ttf);
}

@font-face {
	font-family: Share;
	src: url(../assets/fonts/Share-Bold.ttf);
	font-weight: bold;
}

@font-face {
	font-family: Share;
	src: url(../assets/fonts/Share-BoldItalic.ttf);
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Share;
	src: url(../assets/fonts/Share-Italic.ttf);
	font-style: italic;
}

.font-AkzidenzGroteskPro {
	font-family: AkzidenzGroteskPro;
}

.font-Share {
	font-family: Share;
}
