@font-face {
  font-family: AkzidenzGroteskPro;
  src: url("AkzidenzGroteskPro-ExtItalic.4a9be6a2.woff2");
  font-style: italic;
}

@font-face {
  font-family: Share;
  src: url("Share-Regular.ca36c4d3.ttf");
}

@font-face {
  font-family: Share;
  src: url("Share-Bold.16abe54b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Share;
  src: url("Share-BoldItalic.07fe8ec3.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Share;
  src: url("Share-Italic.0e54e883.ttf");
  font-style: italic;
}

.font-AkzidenzGroteskPro {
  font-family: AkzidenzGroteskPro;
}

.font-Share {
  font-family: Share;
}
/*# sourceMappingURL=index.8008047e.css.map */
